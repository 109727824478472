import { createResource, For, Show, Suspense } from "solid-js";
import { Card, Alert, Divider, } from "@suid/material";
import { showWfCandidateOngoingJobRoleItems, showWfCandidateCompletedJobRoleItems, AvatarWithWfButtons, showWfCandidateJobsBestMatchItems, showWfCandidateJobsNotStaffedItems, showWfCandidateJobsStaffedItems } from '../SmallComponents/SmallAvatarWithWfButtons'
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { CandidateJobMenu } from '../Candidate/CandidateJobMenu'
import { isExtraSmall } from "../../../utils/sizeUtil"
import { getText, GetTextUI } from '../SmallComponents/Translate'

import { CandidateJobFetchType, CandidateJobRolesFetchType } from "../../../types/candidateJobDataTypes"
import { formatDateByCountryLong, formatDateByCountry, formatDateYearMonthByCountry, formatTimeByCountry, isInvoiceDueDatePassed } from "../../../utils/datetimeflag"

const apiUrl = import.meta.env.VITE_APP_API_URL;

const fetchJob = async (jobId: string | null): Promise<CandidateJobFetchType> => {
    const response = await fetch(`${apiUrl}/candidate/candidatejob/${jobId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Job response was not ok.');
    }
    const data = await response.json();
    return data;
}
const fetchJobRoles = async (): Promise<CandidateJobRolesFetchType> => {
    const response = await fetch(`${apiUrl}/candidate/candidatejobroles`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Response from Jobroles was not ok.');
    }
    const data = await response.json();
    return data;
}

const CandidateJobComponent = () => {

    const fetchId = "1"
    const [job] = createResource<CandidateJobFetchType>(() => fetchJob(fetchId));
    const [jobRoles] = createResource<CandidateJobRolesFetchType>(() => fetchJobRoles());

    const wfStatusCodeOngoing = "WFCC3a"
    const wfStatusCodeCompleted = "WFCC3b"

    const txtGoodMatch = getText("jobpage", "goodmatch")
    const txtNotStaffed = getText("jobpage", "notstaffed")
    const txtStaffed = getText("jobpage", "staffed")

    const returnAvatarName = (s1: string, s2: string) => {
        return s1 + " " + s2;
    }

    return (
        <>
            <Card sx={{ margin: 1 }}>

                {/* First row of the job section show the local menubar, tags and other action buttons */}
                <CandidateJobMenu />


                <Divider />

                {/* Relevant info for the candidate's other activities */}

                <Alert sx={{ px: 1, py: 1 }} severity="info">
                    {job()?.rolesFit?.length} <GetTextUI formName={"jobpage"} label={"numbersgoodmatch"} />.
                    {job()?.otherRoles?.length} <GetTextUI formName={"jobpage"} label={"waitingmatch"} />.
                    {job()?.assignedRoles?.length} <GetTextUI formName={"jobpage"} label={"assignedprofiles"} />.
                </Alert>

                <Divider />

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>
                    <Show when={true}>
                        <CardWithMinimize header={"Ongoing Jobs" as string + " (" + jobRoles()?.jobRoles?.length + ")"} type="stack" defaultOpen={true}>
                            <For each={jobRoles()?.jobRoles?.filter((option) => option.wfStatus ===wfStatusCodeOngoing)}>
                                {(item) => <AvatarWithWfButtons showWfItems={showWfCandidateOngoingJobRoleItems}
                                    avatarSize="medium"
                                    avatarName={item.companyName}
                                    role={item.jobRoleName}
                                    jobRoleId={item.jobRoleId}
                                    contractId={item.contractId}
                                    jobName={item.jobName}
                                    jobDescription={item.jobRoleDescription}
                                    rateFlat={item.maxFlatRate}
                                    rateOnsite={item.maxOnSiteRate}
                                    rateRemote={item.maxRemoteRate}
                                    currency={item.currency}
                                    wfStatus={item.wfStatus}
                                    jobStartDate={formatDateYearMonthByCountry(item?.jobRoleStartDate)}
                                    jobEndDate={formatDateYearMonthByCountry(item?.jobRoleEndDate)}
                                    src={item.src} />}
                            </For>
                        </CardWithMinimize>
                    </Show>

                    <Show when={true}>
                        <CardWithMinimize header={"Completed Jobs" as string + " (" + jobRoles()?.jobRoles?.length + ")"} type="stack" defaultOpen={true}>
                            <For each={jobRoles()?.jobRoles?.filter((option) => option.wfStatus ===wfStatusCodeCompleted)}>
                                {(item) => <AvatarWithWfButtons showWfItems={showWfCandidateCompletedJobRoleItems}
                                    avatarSize="medium"
                                    avatarName={item.companyName}
                                    role={item.jobRoleName}
                                    jobRoleId={item.jobRoleId}
                                    jobName={item.jobName}
                                    jobDescription={item.jobRoleDescription}
                                    rateFlat={item.maxFlatRate}
                                    rateOnsite={item.maxOnSiteRate}
                                    rateRemote={item.maxRemoteRate}
                                    currency={item.currency}
                                    wfStatus={item.wfStatus}
                                    jobStartDate={formatDateYearMonthByCountry(item?.jobRoleStartDate)}
                                    jobEndDate={formatDateYearMonthByCountry(item?.jobRoleEndDate)}
                                    src={item.src} />}
                            </For>
                        </CardWithMinimize>
                    </Show>
                </Suspense>

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>
                    <Show when={job()?.rolesFit?.length}>
                        <CardWithMinimize header={txtGoodMatch() as string + " (" + job()?.rolesFit?.length + ")"} type="stack" defaultOpen={isExtraSmall() ? false : true}>
                            <For each={job()?.rolesFit}>
                                {(item) => (<AvatarWithWfButtons showWfItems={showWfCandidateJobsBestMatchItems}
                                    locationCountry={item.jobCountry}
                                    locationCity={item.jobCity}
                                    jobDescription={item.jobDescription}
                                    rateFlat={item.maxFlatRate}
                                    rateOnsite={item.maxOnSiteRate}
                                    rateRemote={item.maxRemoteRate}
                                    currency={item.currency}
                                    avatarSize={"medium"}
                                    avatarName={item.jobRole}
                                    jobStartDate={formatDateYearMonthByCountry(item?.jobStartDate)}
                                    jobEndDate={formatDateYearMonthByCountry(item?.jobEndDate)}
                                    onSiteText={item.onSiteText}
                                    wfStatus={item.wfStatus}
                                    src="" />)}
                            </For>
                        </CardWithMinimize>
                    </Show>
                    <Show when={job()?.otherRoles?.length}>
                        <CardWithMinimize header={txtNotStaffed() as string + " (" + job()?.otherRoles?.length + ")"} type="stack" defaultOpen={isExtraSmall() ? false : false}>

                            <For each={job()?.otherRoles}>
                                {(item, idx) => <AvatarWithWfButtons showWfItems={showWfCandidateJobsNotStaffedItems}
                                    avatarSize="medium"
                                    jobRoleId={item.jobRoleId}
                                    role={item.jobRole}
                                    jobStartDate={formatDateYearMonthByCountry(item?.jobStartDate)}
                                    jobEndDate={formatDateYearMonthByCountry(item?.jobEndDate)}
                                    onSiteText={item.onSiteText}
                                    locationCountry={item.jobCountry}
                                    locationCity={item.jobCity}
                                    rateFlat={item.maxFlatRate}
                                    rateOnsite={item.maxOnSiteRate}
                                    rateRemote={item.maxRemoteRate}
                                    currency={item.currency}
                                    avatarName={item.jobRole}
                                    wfStatus={item.wfStatus}
                                    src="" />}
                            </For>
                        </CardWithMinimize>
                    </Show>
                    <Show when={job()?.assignedRoles?.length}>
                        <CardWithMinimize header={txtStaffed() as string + " (" + job()?.assignedRoles?.length + ")"} type="stack" defaultOpen={false}>
                            <For each={job()?.assignedRoles}>
                                {(item) => <AvatarWithWfButtons showWfItems={showWfCandidateJobsStaffedItems}
                                    avatarSize="medium"
                                    avatarName={item.companyName}
                                    role={item.jobRoleName}
                                    jobName={item.jobName}
                                    jobDescription={item.jobRoleDescription}
                                    locationCountry={item.jobCountry}
                                    locationCity={item.jobCity}
                                    rateFlat={item.maxFlatRate}
                                    rateOnsite={item.maxOnSiteRate}
                                    rateRemote={item.maxRemoteRate}
                                    currency={item.currency}
                                    jobStartDate={formatDateYearMonthByCountry(item?.jobStartDate)}
                                    jobEndDate={formatDateYearMonthByCountry(item?.jobEndDate)}
                                    src={item.src} />}
                            </For>
                        </CardWithMinimize>
                    </Show>

                    <CardWithMinimize header={"Ongoing Matchings" as string + " (" + job()?.assignedRoles?.length + ")"} type="stack" defaultOpen={false}>
                        <For each={job()?.assignedRoles}>
                            {(item) => <AvatarWithWfButtons showWfItems={showWfCandidateJobsStaffedItems}
                                avatarSize="medium"
                                avatarName={returnAvatarName(item.firstName, item.lastName)}
                                role={item.jobRole}
                                jobStartDate={formatDateYearMonthByCountry(item?.jobStartDate)}
                                src={item.src} />}
                        </For>
                    </CardWithMinimize>


                    <Show when={true}>
                        <CardWithMinimize header={"Not selected jobs" as string + " (" + job()?.assignedRoles?.length + ")"} type="stack" defaultOpen={false}>
                            <For each={job()?.assignedRoles}>
                                {(item) => <AvatarWithWfButtons showWfItems={showWfCandidateJobsStaffedItems}
                                    avatarSize="medium"
                                    avatarName={returnAvatarName(item.firstName, item.lastName)}
                                    role={item.jobRole}
                                    jobStartDate={formatDateYearMonthByCountry(item?.jobStartDate)}
                                    src={item.src} />}
                            </For>
                        </CardWithMinimize>
                    </Show>
                </Suspense>

            </Card>
            <br />
            <br />
        </>
    );
}

export { CandidateJobComponent }  